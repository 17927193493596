export enum OrdersInteractionMenus {
    New = 53,
    New_Sales = 54,
    New_Presales = 55,
    New_Company = 83,
    New_Sample = 85,
    Export = 68,
    Export_OrderListCsv = 69,
    Export_OrderContactListCsv = 117,
    Export_OrderListMap = 114,
    RecalculateOrders = 70,
}