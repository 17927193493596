import { BaseAPI, FilterAPI } from 'api';
import { IRelationListItem, IRelationFilterContext, IFilterResultInfo, IContactSearchListItem, ISearchRelationModel, IEventLogFilterContext, IFileDetail } from 'interfaces/models';
import { ICreateCustomerViewModel, IRelationViewModel, ICustomerViewModel, ICreateRelationViewModel, ICreateContactPersonViewModel } from 'interfaces/viewmodels';
import { IErrorMessagesMap } from 'interfaces';
import { useDates, useGlobals } from 'hooks';
import { storeInstance } from 'store'
import { Profiles } from 'enums';

const { rootUrl } = useGlobals();
const { getDateByUtc } = useDates();
const { companyContactId } = useGlobals();

export class RelationAPI {
    public static getFilterContext = (isActive: boolean, companyRelationId: number, parentContactId: number | null, relationTypeId: number | null, onSuccess: (data: IRelationFilterContext) => void) => {
        const account = storeInstance.getState().authenticationState.account;
        FilterAPI.getFilterContext("crm/relation",
            [
                String(isActive),
                String(companyRelationId),
                String(parentContactId || 0),
                String(relationTypeId || 0),
            ],
            (data) => onSuccess({
                ...data, FilterInfo: {
                    ...data.FilterInfo,
                    OrderByRules: [{ FieldName: "Id", IsDescending: true }],
                    PageSize: 500
                }
            }));
    }

    public static filter = (filterContext: IRelationFilterContext, onSuccess: (data: IFilterResultInfo) => void) => {
        BaseAPI.post("api/crm/relation/filter", {
            ...filterContext,
            CountryId: { ...filterContext.CountryId, Values: filterContext.CountryId.Values.filter(v => v.IsChecked) },
            LanguageId: filterContext.LanguageId ? { ...filterContext.LanguageId, Values: filterContext.LanguageId.Values.filter(v => v.IsChecked) } : undefined,
            CreatedOn: filterContext.CreatedOn ? { ...filterContext.CreatedOn, Values: filterContext.CreatedOn.Values.filter(v => v.IsChecked) } : undefined
        }, onSuccess);
    }

    public static search = (search: ISearchRelationModel, onSuccess: (data: IRelationListItem[]) => void) => {
        const account = storeInstance.getState().authenticationState.account;
        BaseAPI.post("api/crm/relation/Search", {
            ...search,
            AgentRelationId: (account.Privilege.ProfileId === Profiles.Sales && search.ParentContactId === companyContactId) ? account.Privilege.RelationId : 0
        }, onSuccess);
    }

    public static read = (parentRelationId: number, relationTypeId: number | undefined, includeParentRelation: boolean, onSuccess: (data: IRelationListItem[]) => void) => {
        BaseAPI.get(`api/crm/relation/read/${parentRelationId}/${relationTypeId || 0}/${includeParentRelation}`, null, onSuccess);
    }

    public static get = (rowGuid: string, onSuccess: (data: IRelationViewModel) => void) => {
        BaseAPI.get(`api/crm/relation/get/${rowGuid}`, null, onSuccess);
    }

    public static getCreate = (isPerson: boolean, relationTypeId: number, companyRelationId: number, parentContactId: number, onSuccess: (data: ICreateRelationViewModel) => void) => {
        BaseAPI.get(`api/crm/relation/create/${isPerson}/${relationTypeId}/${companyRelationId}/${parentContactId}`, null, onSuccess);
    }

    public static create = (relation: ICreateRelationViewModel, onSuccess: (rowGuid: string) => void, onError: (xhr: XMLHttpRequest, errorMessages?: IErrorMessagesMap) => void) => {
        const data: ICreateRelationViewModel = {
            ...relation, Countries: []
        };
        BaseAPI.post("api/crm/relation/create", data, onSuccess, onError);
    }

    public static update = (relation: IRelationViewModel, onSuccess: (response: any) => void, onError: (xhr: XMLHttpRequest, errorMessages?: IErrorMessagesMap) => void) => {
        const data: IRelationViewModel = {
            ...relation,
            Countries: [],
            Languages: [],
            Currencies: [],
        };
        BaseAPI.post("api/crm/relation/Update", data, onSuccess, onError);
    }

    public static getCreateContactPerson = (companyRelationId: number, parentContactId: number, onSuccess: (data: ICreateContactPersonViewModel) => void) => {
        BaseAPI.get(`api/crm/relation/createContactPerson/${companyRelationId}/${parentContactId}`, null, onSuccess);
    }

    public static createContactPerson = (relation: ICreateContactPersonViewModel, onSuccess: (rowGuid: string) => void, onError: (xhr: XMLHttpRequest, errorMessages?: IErrorMessagesMap) => void) => {
        BaseAPI.post("api/crm/relation/createContactPerson", relation, onSuccess, onError);
    }

    public static getCreateCustomer = (companyRelationId: number, parentContactId: number, isProspect: boolean, onSuccess: (data: ICreateCustomerViewModel) => void) => {
        BaseAPI.get(`api/crm/relation/createCustomer/${companyRelationId}/${parentContactId}/${isProspect}`, null, onSuccess);
    }

    public static createCustomer = (relation: ICreateCustomerViewModel, onSuccess: (rowGuid: string) => void, onError: (xhr: XMLHttpRequest, errorMessages?: IErrorMessagesMap) => void) => {
        const account = storeInstance.getState().authenticationState.account;
        const data: ICreateCustomerViewModel = {
            ...relation, Countries: []
        };
        BaseAPI.post("api/crm/relation/createCustomer", data, onSuccess, onError);
    }

    public static getCustomer = (rowGuid: string, onSuccess: (data: ICustomerViewModel) => void, onError?: (xmlHttpRequest: XMLHttpRequest) => void) => {
        BaseAPI.get(`api/crm/relation/getCustomer/${rowGuid}`, null,
            (customer: ICustomerViewModel) => {
                onSuccess({
                    ...customer,
                    CustomerCardReceivedOn: getDateByUtc(customer.CustomerCardReceivedOn),
                    CatalogActiveFrom: getDateByUtc(customer.CatalogActiveFrom),
                    CatalogActiveTo: getDateByUtc(customer.CatalogActiveTo),
                });
            }
            ,onError);
    }

    public static updateCustomer = (relation: ICustomerViewModel, onSuccess: (response: any) => void, onError: (xhr: XMLHttpRequest, errorMessages?: IErrorMessagesMap) => void) => {
        const data: ICustomerViewModel = {
            ...relation,
            Countries: [],
            Languages: [],
            Currencies: [],
            PurchaseAssociations: [],
            OrderInputPolicies: [],
        };
        BaseAPI.post("api/crm/relation/UpdateCustomer", data, onSuccess, onError);
    }

    public static toggleIsActive = (rowGuid: string, onSuccess: (response: any) => void) => {
        BaseAPI.post("api/crm/relation/ToggleIsActive", rowGuid, onSuccess);
    }

    public static clearCatalog = (rowGuid: string, onSuccess: (response: any) => void) => {
        BaseAPI.post("api/crm/relation/ClearCatalog", rowGuid, onSuccess);
    }

    public static delete = (rowGuid: string, onSuccess: (response: any) => void, onError: (xhr: XMLHttpRequest, errorMessages?: IErrorMessagesMap) => void) => {
        BaseAPI.post("api/crm/relation/delete", rowGuid, onSuccess, onError);
    }

    public static uploadAttachment = (rowGuid: string, folderPath: string, file: File | undefined, onSuccess: () => void) => {
        BaseAPI.uploadFile(rowGuid, folderPath, file, "api/crm/relation/uploadAttachment", onSuccess);
    }

    public static readAttachments = (rowGuid: string, onSuccess: (files: IFileDetail[]) => void) => {
        BaseAPI.readFiles(`api/crm/relation/ReadAttachments/${rowGuid}`, onSuccess);
    }

    public static removeAttachments = (rowGuid: string, filePaths: string[], onSuccess: () => void) => {
        BaseAPI.deleteFiles(rowGuid, filePaths, "api/crm/relation/removeAttachments", onSuccess);
    }

    public static getDownloadAttachmentUrl = (rowGuid: string, filePath: string) => {
        return `${rootUrl}/api/crm/relation/DownloadAttachment/${rowGuid}?filePath=${filePath}&t=${Date.now()}`;
    }

    public static getEventLogFilterContext = (rowGuid: string | undefined, onSuccess: (data: IEventLogFilterContext) => void) => {
        BaseAPI.get(`Api/Crm/Relation/GetEventLogFilterContext/${rowGuid}`, null,
            (data) => onSuccess({
                ...data, FilterInfo: {
                    ...data.FilterInfo,
                    PageSize: 50
                }
            }));
    }

    public static createFilteredContactListCsv = (filterContext: IRelationFilterContext, onSuccess: (url: string) => void) => {
        BaseAPI.post(`api/crm/relation/CreateFilteredContactListCsv`, filterContext, onSuccess);
    }
}