import { WindowModal } from 'components/common'
import { Stack } from 'office-ui-fabric-react'
import React, { useEffect, useRef } from 'react'
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
// Import marker icons
import markerIcon from 'leaflet/dist/images/marker-icon.png';
import markerShadow from 'leaflet/dist/images/marker-shadow.png';

export const ContactListMapWindow = (props: ContactListMapWindowProps) => {

    return (
        <WindowModal
            isFullSize
            isOpen
            isBlocking
            titleBarProps={{
                title: "Map",
            }}

            onDismiss={props.onDismiss}>
            <Stack verticalFill>
                <MapView {...props} />
            </Stack>
        </WindowModal>
    )
}

const MapView = (props: Pick<ContactListMapWindowProps, "items">) => {

    const mapRef = useRef<HTMLDivElement>(null);

    useEffect(() => {


        var items = props.items.filter(x => (x.Latitude || 0) !== 0 && (x.Longitude || 0) !== 0)

        if (items.length === 0) return;

        if (!mapRef.current) return;

        var map = L.map(mapRef.current, {
            center: [items[0].Latitude, items[0].Longitude],
            zoom: 7
        });

        const defaultIcon = L.icon({
            iconUrl: "green-marker-icon.png", //markerIcon,
            shadowUrl: markerShadow,
            iconSize: [25, 41],
            iconAnchor: [12, 41],
            popupAnchor: [1, -34],
            shadowSize: [41, 41],
        });

        const prospectIcon = L.icon({
            iconUrl: markerIcon,
            shadowUrl: markerShadow,
            iconSize: [25, 41],
            iconAnchor: [12, 41],
            popupAnchor: [1, -34],
            shadowSize: [41, 41],
        });


        L.tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png', {
            attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        }).addTo(map);

        for (const item of items) {
            L.marker([item.Latitude, item.Longitude], { icon: item.RelationTypeId === 3 ? prospectIcon : defaultIcon }).addTo(map)
                .bindPopup(`${item.Name}`)
        }

        // Cleanup map on unmount
        return () => {
            map.remove();
        };

    }, [props.items])

    return (
        <div
            style={{ height: "100%", width: "100%" }}
            ref={mapRef}
        />
    )
}

interface ContactListMapWindowProps {
    items: any[],
    onDismiss: () => void
}
