import React, { useState, useEffect, useRef } from 'react';
import { ColumnsStack, DownloadFile, IFilterBarItemProps, Loader, LoaderModal, ScrollableContainer, ToggleButtonFilter } from 'components/common';
import { IDocumentsListViewLayoutProps } from './interfaces';
import { DefaultCommandTypes, OrdersInteractionMenus, OrderSubDocumentTypes, Profiles } from 'enums';
import { DocumentFilterContextFields, DocumentListItemFields } from 'enums/fields'
import { DocumentAPI, DictionaryAPI, MenuAPI, RelationAPI } from 'api';
import { IDocumentFilterContext, IDocumentListItem, IFilter, IRangeFilter, IDocumentListTotals } from 'interfaces/models';
import { IContextualMenuItem, DayOfWeek, mergeStyleSets, DefaultPalette, IColumn, Label, getTheme, ICommandBarItemProps } from 'office-ui-fabric-react';
import { IDetailsListColumn, FilterBar, CheckboxFilter, DateRangeFilter } from 'components/common';
import { useFilterContextHandler, useNumberFormat, useDates, useSelection, useUserSetting } from 'hooks';
import { DocumentStatuses } from 'enums/lgs/DocumentStatuses';
import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { ListView } from 'components/common';
import { RecalculateDocumentsDialog } from './RecalculateDocumentsDialog';
import { ContactListMapWindow } from '../../pages/Customers/ContactListMapWindow';

export const DocumentsListViewLayout = (props: IDocumentsListViewLayoutProps) => {

    const {
        documentTypeId,
        documentStatusId,
        menu,
        recalculateDocumentsInteractionMenuId,
        exportInteractionMenuId,
        rowGuid,
        filterBarItemNamesToHide,
        detailsListColumnFieldNamesToHide,
        fromRelationNameHeader,
        relationNameHeader,
        toRelationNameHeader,
        getCreateDocumentComponent,
        getDocumentComponent } = props;

    const [newInteractionMenuId, setNewInteractionMenuId] = useState<number>();
    const [recalculateDocumentsDialogVisible, setRecalculateDocumentsDialogVisible] = useState(false);
    const [downloadFilteredDocumentListCsvUrl, setDownloadFilteredDocumentListCsvUrl] = useState<string>(undefined);
    const [orderContactListMapItems, setOrderContactListMapItems] = useState<any[]>(undefined);

    const [loading, setLoading] = useState(false);

    const {
        COLLECTIONCODE,
        CATALOGCODE,
        INTERNAL_REMARKS,
        NUMBER,
        CREATED_ON,
        SUB_DOCUMENT_TYPE_ID,
        AGENT_CODE,
        FROM_RELATION_NAME,
        RELATION_NAME,
        RELATION_COUNTRY_ID,
        TO_RELATION_NAME,
        QUANTITY,
        DISCOUNT_TOTAL,
        SUB_TOTAL_EXCL_DISCOUNT,
        SUB_TOTAL,
        TOTAL,
        SALES_TREND,
        REFERENCE,
        DOCUMENT_STATUS_ID,
        ROW_GUID
    } = DocumentListItemFields;

    const { COLLECTION_ID, CONFIRMED_ON } = DocumentFilterContextFields;

    const documentStatusIdRef = useRef<number>();
    const documentListTotalsRef = useRef<IDocumentListTotals>();
    const userSettingHook = useUserSetting(menu.Id);
    const account = useSelector((state: RootState) => state.authenticationState.account);
    const customerRelationRowGuid = useSelector((state: RootState) => state.pendingOrdersState.customerRelationRowGuid);

    const cultureId = account.CultureId;

    const { getNumber, getPrice, getPercent } = useNumberFormat(cultureId);
    const { dateByUtcToLocaleDateString } = useDates(cultureId);
    const { push } = useHistory();
    const theme = getTheme();

    const classNames = mergeStyleSets({
        numericColumn: {
            width: "100%",
            textAlign: "right"
        }
    })

    const filterContextHandler = useFilterContextHandler<IDocumentFilterContext>(
        (onSuccess) => {
            DocumentAPI.getFilterContext(documentTypeId, customerRelationRowGuid, onSuccess);
        },
        (filterContext, onSuccess) => {
            if (documentStatusId && !documentStatusIdRef.current) {
                documentStatusIdRef.current = documentTypeId;
                const filterValue = filterContext.DocumentStatusId.Values.find(v => v.Value === documentStatusId);

                if (filterValue) {
                    filterValue.IsChecked = true;
                }
            }
            DocumentAPI.filter(filterContext,
                (viewModel) => {
                    documentListTotalsRef.current = viewModel.DocumentListTotals;
                    onSuccess(viewModel.FilterResultInfo);
                });
        },
        userSettingHook, true);

    const { selection, getFirstSelectedItemIndex } = useSelection<string>(
        ROW_GUID,
        filterContextHandler.filterResultInfo?.Items,
        [],
        filterContextHandler.isFiltering);

    const {
        filterContext,
        filterResultInfo,
        getFilterContext,
        onFilterChange,
        onRangeFilterChange,
        onPageChange,
        onOrderByChange,
        getFilterResultInfo } = filterContextHandler;

    const getNewContextMenuItems = (): IContextualMenuItem[] | undefined => {
        if (props.newInteractionMenuId) {
            return MenuAPI.filterByParent(props.newInteractionMenuId).map((v) => {
                return {
                    key: String(v.Id),
                    text: v.translatedName,
                    onClick: () => setNewInteractionMenuId(v.Id)
                }
            })
        }
    }

    const onExportInteractionMenuClick = (menuId: number) => {

        setLoading(true);

        switch (menuId) {

            case OrdersInteractionMenus.Export_OrderListCsv:
                DocumentAPI.createFilteredDocumentListCsv(filterContext,
                    (url) => {
                        setDownloadFilteredDocumentListCsvUrl(url);
                        setLoading(false);
                    })
                break;

            case OrdersInteractionMenus.Export_OrderContactListCsv:
                DocumentAPI.createFilteredDocumentContactListCsv(filterContext,
                    (url) => {
                        setDownloadFilteredDocumentListCsvUrl(url);
                        setLoading(false);
                    })
                break;

            case OrdersInteractionMenus.Export_OrderListMap:

                DocumentAPI.getDocumentListContacts(filterContext,
                    (documentListContacts) => {
                        if (filterContext.AgentRelationId.Values.filter(x => x.IsChecked).length === 0) {
                            setOrderContactListMapItems(documentListContacts);
                            setLoading(false);
                        } else {
                            RelationAPI.filter({
                                AgentRelationId: filterContext.AgentRelationId,
                                RelationTypeId: {
                                    DisplayName: "",
                                    DisplayOrder: 0,
                                    IsNot: false,
                                    TypeName: "",
                                    ValueNameTranslationRequired: false,
                                    Values: [{ Name: "", IsChecked: true, Value: 3 }]
                                },
                                CountryId: filterContext.RelationCountryId,
                                FilterInfo: { IsActive: true, Page: 0, PageSize: 0, OrderByRules: [], Search: '' }
                            }, (prospects) => {
                                setOrderContactListMapItems(documentListContacts.concat(prospects.Items));
                                setLoading(false);
                            });
                        }
                    })
                break;
            default:
                break;
        }
    }

    const getExportContextualMenuItems = (): IContextualMenuItem[] => {

        return MenuAPI.filterByParent(exportInteractionMenuId).map((v) => {
            return {
                key: String(v.Id),
                text: DictionaryAPI.getTranslation(v.Name),
                onClick: () => onExportInteractionMenuClick(v.Id)
            }
        })

    }

    const onDefaultCommandClick = (type: DefaultCommandTypes) => {
        switch (type) {
            case DefaultCommandTypes.New:
                setNewInteractionMenuId(-1);
                break;

            default:
                break;
        }
    }

    const getOverflowCommandBarItems = (): ICommandBarItemProps[] | undefined => {

        if (recalculateDocumentsInteractionMenuId) {
            const menu = MenuAPI.find(recalculateDocumentsInteractionMenuId);

            if (menu) {
                return [
                    {
                        key: String(menu.Id),
                        text: menu.translatedName,
                        iconProps: { iconName: "Sync" },
                        onClick: () => setRecalculateDocumentsDialogVisible(true)
                    },
                    /* {
                        key: String("test"),
                        text: "Dowload",
                        iconProps: { iconName: "Download" },
                        onClick: () => setDownloadFilteredDocumentListCsv(true)
                    } */
                ]
            }
        }

        return undefined;
    }


    const getDetailsListColumns = (): IDetailsListColumn[] => {

        let columns: IDetailsListColumn[] = [
            { fieldName: COLLECTIONCODE, width: 70, name: "Collection" },
            { fieldName: NUMBER, width: 40, name: "Nr" },
            { fieldName: CREATED_ON, width: 70, name: "Created" },
            { fieldName: RELATION_NAME, width: 150, name: relationNameHeader, isRowHeader: true, linkProps: menu.Privilege.EditAllowed && { baseUrl: "#" + menu.Path, urlParameterFieldName: ROW_GUID } },
            { fieldName: RELATION_COUNTRY_ID, width: 60, name: "Country" },
            { fieldName: QUANTITY, width: 40, name: "Abv_Quantity" },
            { fieldName: SUB_TOTAL_EXCL_DISCOUNT, width: 80, name: "SubtotalExclDiscount" },
            { fieldName: DISCOUNT_TOTAL, width: 80, name: "DiscountTotal" },
            { fieldName: SUB_TOTAL, width: 80, name: "Subtotal" },
            { fieldName: TOTAL, width: 80, name: "Total" },
            { fieldName: SALES_TREND, width: 60, name: "Trend" },
            { fieldName: SUB_DOCUMENT_TYPE_ID, width: 70, name: "Type" },
            { fieldName: REFERENCE, width: 175 },
            { fieldName: DOCUMENT_STATUS_ID, width: 90, name: "Status" },
            { fieldName: AGENT_CODE, width: 70, name: "Agent" },
            /* { fieldName: CATALOGCODE, width: 70, name: "Catalog" }, */
            { fieldName: FROM_RELATION_NAME, width: 70, name: fromRelationNameHeader },
            { fieldName: TO_RELATION_NAME, width: 150, name: toRelationNameHeader },
            { fieldName: INTERNAL_REMARKS, width: 200 },
        ].filter(c => !detailsListColumnFieldNamesToHide?.includes(c.fieldName));

        if (account.Privilege.ProfileId === Profiles.DealerAccessories) {

            const columnsToHide: string[] = [
                RELATION_NAME,
                RELATION_COUNTRY_ID,
                SALES_TREND,
                REFERENCE,
                AGENT_CODE,
                CATALOGCODE,
                FROM_RELATION_NAME,
                INTERNAL_REMARKS
            ]

            columns = columns.filter(c => !columnsToHide.includes(c.fieldName))
        }

        return columns;
    }

    const onRenderDetailsListItemColumn = (item?: IDocumentListItem, index?: number | undefined, column?: IColumn | undefined): React.ReactNode | undefined => {
        if (item && column && column.fieldName) {

            switch (column.fieldName) {
                case CREATED_ON:
                    return <span>{dateByUtcToLocaleDateString(item[column.fieldName])}</span>
                case QUANTITY:
                    return <div className={classNames.numericColumn}>{getNumber(item[column.fieldName], 0)}</div>
                case SUB_TOTAL_EXCL_DISCOUNT:
                case DISCOUNT_TOTAL:
                case SUB_TOTAL:
                case TOTAL:
                    return <div className={classNames.numericColumn}>{getPrice(item[column.fieldName])}</div>
                case SALES_TREND:
                    return (
                        <div
                            className={classNames.numericColumn}
                            style={{ color: item[column.fieldName] < 0 ? DefaultPalette.red : DefaultPalette.green }}
                        >
                            {getPercent(item[column.fieldName], 0)}
                        </div>
                    )
                case SUB_DOCUMENT_TYPE_ID:
                    return <span>{DictionaryAPI.getTranslation(OrderSubDocumentTypes[item.SubDocumentTypeId])}</span>
                case DOCUMENT_STATUS_ID:
                    const orderStatusName = DictionaryAPI.getTranslation(DocumentStatuses[item.DocumentStatusId]);
                    switch (item.DocumentStatusId) {
                        case DocumentStatuses.Pending:
                            return <span style={{ color: DefaultPalette.blue }}>{orderStatusName}</span>
                        case DocumentStatuses.Processing:
                            return <span style={{ color: DefaultPalette.orangeLight }}>{orderStatusName}</span>
                        case DocumentStatuses.Executed:
                            return <span style={{ color: DefaultPalette.green }}>{orderStatusName}</span>
                        case DocumentStatuses.Cancelled_Company:
                        case DocumentStatuses.Cancelled_Production:
                        case DocumentStatuses.Cancelled_Customer:
                            return <span style={{ color: DefaultPalette.red }}>{orderStatusName}</span>
                        default:
                            return <span>{orderStatusName}</span>
                    }
                default:
                    break;
            }

            return <span>{item[column.fieldName]}</span>
        }
    }

    const onRenderFilterBar = (): JSX.Element | undefined => {

        if (filterContext) {
            return (
                <FilterBar
                    filterContextHandler={filterContextHandler}
                    filterBarItemNamesToHide={filterBarItemNamesToHide}
                    onRenderFilterBarItem={onRenderFilterBarItem}
                />)
        }
    }

    const onRenderFilterBarItem = (filterBarItemProps: IFilterBarItemProps) => {
        const { filter, name } = filterBarItemProps;

        switch (name) {
            case COLLECTION_ID:
                return (
                    <ToggleButtonFilter
                        filter={filter as IFilter}
                        name={name}
                        onChange={onFilterChange} />
                )
            case CREATED_ON:
            case CONFIRMED_ON:
                return (
                    <DateRangeFilter
                        cultureId={cultureId}
                        firstDayOfWeek={DayOfWeek.Monday}
                        filter={filter as IRangeFilter}
                        name={name}
                        onChange={onRangeFilterChange}
                    />
                )

            default:
                return (
                    <CheckboxFilter
                        filter={filter as IFilter}
                        name={name}
                        onChange={onFilterChange} />
                )
        }
    }

    const getTotalsPanelConent = (): React.ReactNode => {

        if (documentListTotalsRef.current) {
            const valueStyle: React.CSSProperties = {
                textAlign: "right"
            }

            return (
                <ScrollableContainer
                    width={250}
                    padding={10}
                >
                    <div>
                        <div
                            className="ms-depth-8"
                            style={{
                                padding: 10,
                                backgroundColor: theme.semanticColors.bodyBackground,
                            }}>
                            <ColumnsStack verticalAlign="center">
                                <Label>{DictionaryAPI.getTranslation("Quantity")}</Label>
                                <div style={valueStyle}>{getNumber(documentListTotalsRef.current.Quantity)}</div>
                            </ColumnsStack>
                            <ColumnsStack verticalAlign="center">
                                <Label>{DictionaryAPI.getTranslation("SubtotalExclDiscount")}</Label>
                                <div style={valueStyle}>{getPrice(documentListTotalsRef.current.SubtotalExclDiscount)}</div>
                            </ColumnsStack>
                            <ColumnsStack verticalAlign="center">
                                <Label>{DictionaryAPI.getTranslation("Subtotal")}</Label>
                                <div style={valueStyle}>{getPrice(documentListTotalsRef.current.Subtotal)}</div>
                            </ColumnsStack>
                        </div>
                    </div>
                </ScrollableContainer>
            )
        }
    }

    useEffect(() => {
        if (userSettingHook.userSettingsLoaded) {
            getFilterContext();
        }
    }, [customerRelationRowGuid, userSettingHook.userSettingsLoaded])

    return (
        <>
            <ListView
                menu={menu}
                title={menu.translatedName}
                newContextMenuItems={getNewContextMenuItems()}
                exportContextMenuItems={getExportContextualMenuItems()}
                overflowCommandBarItems={getOverflowCommandBarItems()}
                detailsListColumns={getDetailsListColumns()}
                onRenderDetailsListItemColumn={onRenderDetailsListItemColumn}
                orderByRules={filterContext?.FilterInfo?.OrderByRules}
                items={filterResultInfo?.Items}
                selection={selection}
                getInitialFocusedIndex={getFirstSelectedItemIndex}
                pagingProps={filterContext && filterResultInfo && {
                    itemCount: filterResultInfo.ItemCount,
                    pageCount: filterResultInfo.PageCount,
                    page: filterContext.FilterInfo.Page,
                    pageSize: filterContext.FilterInfo.PageSize,
                    onPageChanged: onPageChange
                }}
                loading={!filterContext}
                onDefaultCommandClick={onDefaultCommandClick}
                onRefreshButtonClick={getFilterResultInfo}
                onOrderByChange={onOrderByChange}
                onRenderFilterBar={onRenderFilterBar}
                onRenderInformationPanelContent={getTotalsPanelConent}
            />
            {
                newInteractionMenuId && (
                    getCreateDocumentComponent((rowGuid) => {
                        setNewInteractionMenuId(undefined);
                        getFilterResultInfo(true);
                        push(`${menu.Path}/${rowGuid}`);
                    },
                        () => setNewInteractionMenuId(undefined),
                        newInteractionMenuId
                    )
                )
            }
            {
                rowGuid && (
                    getDocumentComponent(rowGuid, (changesUpdated?: boolean) => {
                        if (changesUpdated) {
                            getFilterResultInfo(false);
                        }

                        push(menu.Path);
                    })
                )
            }
            {
                recalculateDocumentsDialogVisible && (
                    <RecalculateDocumentsDialog
                        documentTypeId={documentTypeId}
                        recalculateDocumentsInteractionMenuId={recalculateDocumentsInteractionMenuId}
                        onDismiss={(updated) => {
                            if (updated) {
                                getFilterResultInfo(false);
                            }

                            setRecalculateDocumentsDialogVisible(false);
                        }}
                    />
                )
            }
            {
                loading && (
                    <LoaderModal />
                )
            }
            <DownloadFile
                url={downloadFilteredDocumentListCsvUrl}
            />
            {orderContactListMapItems && (
                <ContactListMapWindow
                    items={orderContactListMapItems}
                    onDismiss={() => setOrderContactListMapItems(undefined)}
                />
            )}
        </>
    );
};